import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';
import View, { ViewProps } from '../common/View';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import { IsOrRadioForm } from '../common/form/IsOrRadioForm';
import { store } from '../initStore';
import { SignupFields, UtmParams } from '../../interfaces';
import validators from '../../utils/validators';
import { getOpportunity } from 'api';

interface ISignUpState {
    fields: SignupFields;
    errors: SignupFields;
    activeElement: string;
    utmParams: UtmParams;
}
// Keep these out of component so reference doesn't change for useEffect between renders
const initialValues = { closeDate: new Date().toISOString().substring(0, 10), dba: '', firstName: '', lastName: '', locationCount: '1', email: '', phone: '', merchantType: 'business', soleProprietorship: 'No' };
const initialErrors = { closeDate: '', dba: '', firstName: '', lastName: '', locationCount: '', email: '', phone: '', merchantType: '', soleProprietorship: '' };
const initialUtmParams = { source: '', medium: '', campaign: '', keyword: '', content: '' };

const useSignUpState = (props: ViewProps) => {
    const [signUpState, setSignUpState] = React.useState<ISignUpState>({
        fields: props.signup ? { ...props.signup } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
        utmParams: { ...initialUtmParams },
    });

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        const partner = currentUrl.searchParams.get('partner');
        const pricingCode = currentUrl.searchParams.get('pricingCode');
        const opportunity = currentUrl.searchParams.get('opportunity');

        const fetchOpportunity = async (id: string) => {
            try {
                const { closeDate, cnpAgreementRequired, dba } = await getOpportunity(id);
                store.dispatch({type: 'SET_CNP', payload: { cnpAgreementRequired }});
                const isPreviousDate = !!validators.closeDate(closeDate).closeDate;
                const dateToLoad = isPreviousDate ? signUpState.fields.closeDate : closeDate || signUpState.fields.closeDate;
                setSignUpState(prevState => ({ ...prevState, fields: { ...prevState.fields, closeDate: dateToLoad, dba }, errors: { ...prevState.errors } }));
            } catch (err) {
                if (err && err.response && err.response.status) {
                    if (err.response.status === 405) {
                        store.dispatch({
                            type: "error",
                            payload: { error: "Your application has already been submitted." },
                        });
                    } else if (err.response.status === 404) {
                        store.dispatch({
                            type: "error",
                            payload: { error: "We couldn't find your application." },
                        });
                    } else {
                        store.dispatch({
                            type: "error",
                            payload: { error: err.message },
                        });
                    }
                } else {
                    store.dispatch({
                        type: "error",
                        payload: { error: err.message },
                    });
                }
                props.history.push("/instant/error");
            }
        };

        if (partner) store.dispatch({ type: 'SET_PARTNER', payload: { partner }})
        if (pricingCode) store.dispatch({ type: 'SET_PRICING_CODE', payload: { pricingCode } });
        if (opportunity) {
            if (opportunity !== props.opportunityId) {
                setSignUpState(prevState => ({ errors: initialErrors, fields: { ...initialValues }, activeElement: null, utmParams: { ...initialUtmParams }}));
                store.dispatch({ type: 'reset' }); // Don't want to present saved values for another Oppty
            }
            store.dispatch({ type: 'opportunity', payload: { opportunity } });
            fetchOpportunity(opportunity);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history, props.opportunityId]);

    const validations = {
        phone: () => validators.phone(signUpState.fields.phone),
        closeDate: () => validators.closeDate(signUpState.fields.closeDate),
        dba: () => validators.oneAlphaRequired(signUpState.fields.dba, 'dba', 'Doing Business As'),
        firstName: () => validators.oneAlphaRequired(signUpState.fields.firstName, 'firstName', 'First Name'),
        lastName: () => validators.oneAlphaRequired(signUpState.fields.lastName, 'lastName', 'Last Name'),
        locationCount: () => validators.wholeNumber(signUpState.fields.locationCount, 'locationCount', 'Number of Locations', 1),
        email: () => validators.email(signUpState.fields.email),
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            if (validation) {
                const newError = validation();
                setSignUpState(prevState => ({ ...prevState, errors: { ...prevState.errors, ...newError }}));
            }
        }
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;
            setSignUpState(prevState => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    soleProprietorship: name === 'merchantType'
                        ? value === 'business' ? 'No' : 'Yes'
                        : prevState.fields.soleProprietorship,
                    [name]: value,
                },
                errors: { ...prevState.errors, [name]: null }
            }));
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.value || e.currentTarget.value;
            setSignUpState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: { ...prevState.errors, [name]: null } }));
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({
                type: 'signup',
                payload: {
                    signup: { ...signUpState.fields },
                    utmParams: { ...signUpState.utmParams },
                },
            });
            signUpState.fields.soleProprietorship === 'No'
                ? props.history.push('/instant/business/dba')
                : props.history.push('/instant/personal/dba');
        } else {
            console.log({ text: `User failed validation for signup step`, fields: signUpState.errors });
        }
    }

    function formIsValid() {
        let newErrors = {} as SignupFields;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        });
        setSignUpState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';
        setSignUpState(prevState => ({ ...prevState, activeElement: name }));
    }

    return {
        signUpState,
        handleChange,
        elementBlur,
        handleCleaveChange,
        handleSubmit,
        elementActive,
    }
}

const Signup = (props: ViewProps) => {
    const {
        signUpState,
        handleChange,
        elementBlur,
        handleCleaveChange,
        handleSubmit,
        elementActive,
    } = useSignUpState(props);
    const { loading = false, opportunityId = null } = props;
    const { closeDate, dba, firstName, lastName, locationCount, email, phone, merchantType, soleProprietorship } = signUpState.fields;

    return <View>
        <Aside
            explainer={<>
                <h1>GETTING STARTED</h1>
                <p>First, the basics.</p>
                <p>Here are the documents you’ll need to complete the application:</p>
                <ul className="pl4 ma0 mt2">
                    <li>Valid Government ID</li>
                    <li>Voided Check</li>
                </ul>
            </>}
            field={signUpState.activeElement}
        />
        <form className="gp-form" id="#signup" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={0} />
                <div className="form-group">
                    <div className="input-full">
                        <label>Doing Business As</label>
                        <input onFocus={elementActive} onBlur={elementBlur} value={dba} onChange={handleChange} type="text" name="dba" maxLength={30} required disabled={loading || opportunityId} />
                        {signUpState.errors.dba && <div className="error-message">{signUpState.errors.dba}</div>}
                    </div>

                    <div className="input-half">
                        <label className="db">First Name</label>
                        <input onFocus={elementActive} onBlur={elementBlur} value={firstName} onChange={handleChange} type="text" name="firstName" maxLength={40} required />
                        {signUpState.errors.firstName && <div className="error-message">{signUpState.errors.firstName}</div>}
                    </div>

                    <div className="input-half">
                        <label className="db">Last Name</label>
                        <input onFocus={elementActive} onBlur={elementBlur} value={lastName} onChange={handleChange} type="text" name="lastName" maxLength={80} required />
                        {signUpState.errors.lastName && <div className="error-message">{signUpState.errors.lastName}</div>}
                    </div>

                    <div className="input-half">
                        <label className="db">Personal Email</label>
                        <input onFocus={elementActive} onBlur={elementBlur} value={email} onChange={handleChange} name="email" type="email" maxLength={40} required />
                        {signUpState.errors.email && <div className="error-message">{signUpState.errors.email}</div>}
                    </div>

                    <div className="input-half">
                        <label className="db">Personal Phone</label>
                        <Cleave
                            name="phone"
                            data-dd-privacy="mask-user-input"
                            onBlur={elementBlur}
                            value={phone}
                            options={{ blocks: [3, 3, 4], delimiters: ['-', '-'], numericOnly: true }}
                            onChange={handleCleaveChange}
                            onFocus={elementActive}
                            required
                            inputMode="numeric" />
                        {signUpState.errors.phone && <div className="error-message">{signUpState.errors.phone}</div>}
                    </div>
                    <div className="input-half">
                        <label className="db">Desired Start Date</label>
                        <Cleave
                            name="closeDate"
                            onBlur={elementBlur}
                            value={closeDate}
                            options={{
                                date: true,
                                datePattern: ['Y', 'm', 'd'],
                                // dateMin: new Date().toISOString(),
                                delimiter: '-',
                            }}
                            onChange={handleCleaveChange}
                            onFocus={elementActive}
                            required
                        />
                        {signUpState.errors.closeDate && <div className="error-message">{signUpState.errors.closeDate}</div>}
                    </div>
                    <div className="input-half">
                        <label>Number of Locations</label>
                        <Cleave
                            name="locationCount"
                            onBlur={elementBlur}
                            value={locationCount}
                            options={{
                                numeralPositiveOnly: true,
                                numericOnly: true,
                            }}
                            onChange={handleCleaveChange}
                            min={1}
                            maxLength={3}
                            required
                            inputMode="numeric"
                        />
                        {signUpState.errors.locationCount && <div className="error-message">{signUpState.errors.locationCount}</div>}
                    </div>
                    <div className="mt2">
                        <IsOrRadioForm
                            label="Are your business taxes paid through your Social Security Number (SSN/TIN) or a separate Federal Tax ID (EIN/TIN)?"
                            name="merchantType"
                            values={['sole proprietorship', 'business']}
                            labels={['SSN', 'EIN/TIN']}
                            selected={merchantType}
                            onFocus={elementActive}
                            className="w-50"
                            handleChange={handleChange} />
                        {(merchantType === 'sole proprietorship') && <IsOrRadioForm
                            label="Is your business a Sole Proprietorship?"
                            name="soleProprietorship"
                            values={['Yes', 'No']}
                            selected={soleProprietorship}
                            onFocus={elementActive}
                            className="w-50"
                            handleChange={handleChange} />}
                    </div>
                </div>

                <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ signup: state.signup, loading: state.loading, opportunityId: state.opportunityId });
export default connect(mapStateToProps)(withRouter(Signup));
